import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut
} from '@repo/ui/components/Command.js'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/Dialog.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router'
import { ContractStatusBadge } from '~/components/ContractStatusBadge'
import { RoleGuard } from '~/components/RoleGuard'
import { VendorIcon } from '~/components/VendorIcon'
import { useFetcher } from '~/hooks/useFetcher'
import { type ContractSearchResult, SearchContractsSchema } from './routeConfig'

export const ContractSearch = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<ContractSearchResult[]>([])

  const navigate = useNavigate()

  const { submit: search, isSubmitting: isSearching } = useFetcher({
    schema: SearchContractsSchema,
    config: { action: '/api/contracts' },
    onSuccess: (results) => setResults(results as ContractSearchResult[])
  })

  const handleSearch = (query: string) => {
    if (!query) return setResults([])
    if (query.length < 3) return
    search({ query })
  }

  const resetSearch = useCallback(() => {
    setQuery('')
    setResults([])
  }, [])

  const goTo = (url: string) => {
    flushSync(() => setDialogOpen(false))
    navigate(url)
  }

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setDialogOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  useEffect(() => {
    if (!dialogOpen) setTimeout(resetSearch, 200) // Avoid flickering
  }, [dialogOpen, resetSearch])

  const listRef = useRef(null)

  return (
    <>
      <button
        type="button"
        onClick={() => setDialogOpen(true)}
        className="flex items-center justify-between px-3 w-full h-10 bg-white/10 rounded-md border-white/10 text-white/70 hover:bg-white/10 hover:text-white text-xs transition-colors duration-300 group"
      >
        <div className="flex items-center gap-2">
          <Icon name="search" className="size-3" />
          <span>Search contracts...</span>
        </div>

        <CommandShortcut className="bg-white/60 group-hover:bg-white/80 font-mono py-px px-1 rounded text-primary/80 text-xxs transition-colors duration-300">
          ⌘K
        </CommandShortcut>
      </button>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogHeader className="sr-only">
          <DialogTitle>Universal search</DialogTitle>
        </DialogHeader>
        <DialogContent className="p-0 [&>[data-slot='close']]:hidden">
          <Command loop shouldFilter={false}>
            <CommandInput
              placeholder="Search contracts..."
              value={query}
              onValueChange={(query) => {
                setQuery(query)
                handleSearch(query)
              }}
              loading={isSearching}
            />

            <CommandList
              ref={listRef}
              className="p-1.5 fixed w-full bg-white rounded-md top-14"
            >
              {results.length ? (
                <CommandGroup heading="Contracts">
                  {results.map((contract) => (
                    <CommandItem
                      key={contract.id}
                      value={contract.id}
                      onSelect={() => goTo(`/stack/${contract.id}`)}
                      className="flex items-center gap-2"
                    >
                      <VendorIcon
                        src={contract.vendor.iconUrl}
                        className="size-5"
                      />
                      <span>{contract.name}</span>
                      <ContractStatusBadge status={contract.status} size="sm" />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ) : query && isSearching ? (
                <CommandEmpty className="p-3 font-medium w-full bg-muted">
                  <span className="text-muted-foreground">
                    No results found.
                  </span>
                </CommandEmpty>
              ) : (
                <>
                  <CommandGroup heading="Pages">
                    <CommandItem onSelect={() => goTo('/stack')}>
                      <Icon name="stack" />
                      Stack
                    </CommandItem>
                    <CommandItem onSelect={() => goTo('/renewals')}>
                      <Icon name="calendar" />
                      Renewals
                    </CommandItem>
                    <RoleGuard roles={['admin']}>
                      <CommandItem onSelect={() => goTo('/users')}>
                        <Icon name="users" />
                        Users
                      </CommandItem>
                      <CommandItem onSelect={() => goTo('/settings')}>
                        <Icon name="gear" />
                        Settings
                      </CommandItem>
                    </RoleGuard>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </DialogContent>
      </Dialog>
    </>
  )
}
