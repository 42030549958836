import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { Logo } from '@repo/ui/components/Logo.js'
import { Separator } from '@repo/ui/components/Separator.js'
import { SidebarTrigger } from '@repo/ui/components/Sidebar.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@repo/ui/components/Tooltip.js'
import { z } from 'zod'
import { UserAvatar } from '~/components/UserAvatar'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { useFetcher } from '~/hooks/useFetcher'
import { GlobalSpinner } from '../GlobalSpinner'
import { OrgPicker } from './OrgPicker'

const LogoutSchema = z.object({
  intent: z.literal('logout')
})

export const TOPBAR_HEIGHT = '3rem'

export const AppTopbar = () => {
  const user = useAuthenticatedUser()

  const logout = useFetcher({
    schema: LogoutSchema,
    config: { action: '/logout' }
  })

  return (
    <div
      className="flex items-center justify-between border-b border-b-border px-5 overflow-x-auto shrink-0 bg-brand-50 fixed w-full z-20"
      style={{ height: TOPBAR_HEIGHT }}
    >
      <div className="flex justify-start gap-4 items-stretch">
        <Tooltip>
          <TooltipTrigger asChild>
            <SidebarTrigger className="hover:bg-transparent place-self-center opacity-70" />
          </TooltipTrigger>

          <TooltipContent align="start" className="z-50">
            <div className="flex items-center gap-4 justify-between">
              <span>Toggle sidebar</span>
              <span className="text-xs text-muted-foreground font-mono tracking-widest">
                ⌘B
              </span>
            </div>
          </TooltipContent>
        </Tooltip>
        <div className="flex items-center justify-center">
          <Logo className="w-32" />
        </div>
        <Separator
          orientation="vertical"
          className="w-0.5 rotate-12 bg-secondary-foreground/10"
        />
        <OrgPicker />

        <GlobalSpinner />
      </div>

      <div className="flex items-stretch gap-4">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <UserAvatar src={user.avatarUrl} className="size-7" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              disabled={logout.isSubmitting}
              onSelect={(e) => {
                e.preventDefault()
                logout.submit()
              }}
            >
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
