import { SidebarProvider } from '@repo/ui/components/Sidebar.js'
import type { PropsWithChildren } from 'react'
import { AppSidebar } from './AppSidebar'
import { AppTopbar, TOPBAR_HEIGHT } from './AppTopbar'

export const AppLayout = ({ children }: PropsWithChildren) => {
  return (
    <SidebarProvider className="bg-slate-50/50">
      <AppTopbar />
      <AppSidebar />
      <main
        className="relative flex h-full min-h-svh flex-1 flex-col bg-background px-8 py-4 pb-12"
        style={{
          marginTop: TOPBAR_HEIGHT,
          minHeight: `calc(100svh - ${TOPBAR_HEIGHT})`
        }}
      >
        {children}
      </main>
    </SidebarProvider>
  )
}
