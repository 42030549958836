import { Outlet, useRouteError } from 'react-router'
import { ErrorCard } from '~/components/ErrorCard'
import { AppLayout } from '~/components/layout/AppLayout'
import { requireAuth } from '~/services/auth.server'
import type { Route } from './+types/route'

export const loader = async ({ request }: Route.LoaderArgs) => {
  await requireAuth(request)
  return null
}

export default function AppLayoutRoute() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  return (
    <AppLayout>
      <div className="flex flex-col items-center justify-center h-full">
        <ErrorCard error={error} />
      </div>
    </AppLayout>
  )
}
