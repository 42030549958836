import { Button } from '@repo/ui/components/Button.js'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { Spinner } from '@repo/ui/components/Spinner.js'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useActiveOrg } from '~/hooks/useActiveOrg'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { useFetcher } from '~/hooks/useFetcher'
import { useMemberships } from '~/hooks/useMemberships'
import { SwitchOrganizationSchema } from '~/routes/api.organizations/routeConfig'

export const OrgPicker = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()

  const org = useAuthenticatedOrg()
  const auth = useActiveOrg()
  const memberships = useMemberships()

  const showPortfolio =
    memberships.filter((m) => m.role !== 'restricted').length > 1

  const { submit, isSubmitting } = useFetcher({
    schema: SwitchOrganizationSchema,
    config: { action: '/api/organizations' },
    onSuccess: () => setMenuOpen(false)
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  useEffect(() => {
    setMenuOpen(false)
  }, [auth.orgId, auth.portfolioMode])

  return (
    <>
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="font-medium hover:bg-primary/5"
          >
            {auth.portfolioMode ? (
              <div className="flex items-center gap-2">
                <Icon name="chart-simple" size="sm" className="text-brand" />
                <div>Portfolio</div>
              </div>
            ) : (
              <div>{org.name}</div>
            )}

            <Icon name="chevrons-up-down" className="size-3.5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="min-w-60">
          {isSubmitting ? (
            <div className="py-6 flex items-center justify-center">
              <Spinner size="lg" />
            </div>
          ) : (
            <>
              {memberships.map((membership) => {
                const isActive =
                  membership.orgId === org.id && !auth.portfolioMode
                return (
                  <DropdownMenuItem
                    key={membership.orgId}
                    disabled={isActive}
                    className="flex items-center gap-2"
                    onSelect={(e) => {
                      e.preventDefault()
                      submit({
                        orgId: membership.orgId,
                        sourceUrl: location.pathname
                      })
                    }}
                  >
                    <div className="size-4 flex items-center justify-center">
                      {isActive ? <Icon name="circle-check" size="sm" /> : null}
                    </div>
                    <div>
                      <div className="font-medium">{membership.orgName}</div>
                      <div className="text-xs capitalize opacity-80">
                        {membership.role}
                      </div>
                    </div>
                  </DropdownMenuItem>
                )
              })}
              {showPortfolio ? (
                <>
                  <DropdownMenuSeparator />

                  <DropdownMenuItem
                    disabled={auth.portfolioMode}
                    className="flex items-center gap-2"
                    onSelect={(e) => {
                      e.preventDefault()
                      submit({
                        orgId: org.id,
                        portfolioMode: 'true',
                        sourceUrl: location.pathname
                      })
                    }}
                  >
                    <div className="size-4 flex items-center justify-center">
                      <Icon name="chart-simple" size="sm" />
                    </div>
                    <div>
                      <div className="font-medium">Portfolio</div>
                      <div className="text-xs opacity-80">
                        View data across your orgs
                      </div>
                    </div>
                  </DropdownMenuItem>
                </>
              ) : null}
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
