import type { UserRole } from '@repo/db/schema'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@repo/ui/components/Collapsible.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  SidebarSeparator,
  useSidebar
} from '@repo/ui/components/Sidebar.js'
import type { IconName } from '@repo/ui/icons/icons'
import { cn } from '@repo/ui/utils/utils'
import { type ComponentProps, useMemo } from 'react'
import { NavLink, href } from 'react-router'
import { useActiveOrg } from '~/hooks/useActiveOrg'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { ContractSearch } from '~/routes/api.contracts/ContractSearch'
import { TOPBAR_HEIGHT } from './AppTopbar'

const OrgLinks: SidebarLink[] = [
  {
    name: 'Dashboard',
    path: href('/'),
    icon: 'gauge',
    end: true
  },
  {
    name: 'Stack',
    path: href('/stack'),
    icon: 'stack'
  },
  {
    name: 'Renewals',
    path: href('/renewals'),
    icon: 'calendar'
  },
  {
    name: 'Users',
    path: href('/users'),
    icon: 'users',
    roles: ['admin']
  },
  {
    name: 'Settings',
    path: href('/settings'),
    icon: 'gear',
    roles: ['admin']
  }
]

const PortfolioLinks: SidebarLink[] = [
  {
    name: 'Dashboard',
    path: href('/portfolio'),
    icon: 'gauge',
    end: true
  },
  {
    name: 'Vendors',
    path: href('/portfolio/vendors'),
    icon: 'building'
  }
]

export const AppSidebar = () => {
  const { state } = useSidebar()
  const { portfolioMode } = useActiveOrg()

  const links = portfolioMode ? PortfolioLinks : OrgLinks

  return (
    <Sidebar
      collapsible="icon"
      style={{ height: `calc(100svh - ${TOPBAR_HEIGHT})`, top: TOPBAR_HEIGHT }}
    >
      <SidebarContent>
        {!portfolioMode ? (
          <SidebarGroup
            className={cn(
              state === 'collapsed' && 'absolute -left-full -top-full'
            )}
          >
            <SidebarGroupContent>
              <ContractSearch />
            </SidebarGroupContent>
          </SidebarGroup>
        ) : null}

        <AppSidebarLinks links={links} />
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  )
}

type SidebarLink = {
  name: string
  path: string
  icon: IconName
  roles?: UserRole[]
  end?: boolean
}

const AdminLinks: SidebarLink[] = [
  {
    name: 'Organizations',
    path: href('/admin/orgs'),
    icon: 'building'
  },
  {
    name: 'Users',
    path: href('/admin/users'),
    icon: 'users'
  },
  {
    name: 'Vendors',
    path: href('/admin/vendors'),
    icon: 'building'
  }
]

export const AppSidebarLinks = ({ links }: { links: SidebarLink[] }) => {
  const { state } = useSidebar()

  const { role, isInternal } = useAuthenticatedUser()

  if (!role) return null

  const filteredLinks = useMemo(
    () => links.filter((l) => !l.roles || l.roles.includes(role)),
    [links, role]
  )

  return (
    <>
      <SidebarGroup>
        <SidebarGroupContent>
          <SidebarMenu>
            {filteredLinks.map((link) => (
              <SidebarMenuItem key={link.name}>
                <SidebarNavItem to={link.path} end={link.end}>
                  {({ isActive }) => (
                    <SidebarMenuButton isActive={isActive}>
                      <Icon
                        name={link.icon}
                        className={cn(isActive && 'text-sidebar-accent')}
                      />
                      {link.name}
                    </SidebarMenuButton>
                  )}
                </SidebarNavItem>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>

      {isInternal && state !== 'collapsed' ? (
        <>
          <SidebarSeparator className="opacity-50" />
          <Collapsible defaultOpen className="group/collapsible">
            <SidebarGroup>
              <SidebarGroupLabel
                asChild
                className="text-sm text-sidebar-foreground hover:bg-sidebar-accent/10 mb-1"
              >
                <CollapsibleTrigger
                  className={`flex items-center gap-4 justify-between [&>[data-slot="icon"]]:size-3`}
                >
                  <div className="flex items-center gap-3">
                    <Icon name="shield" />
                    <span>Admin</span>
                  </div>
                  <Icon
                    name="chevron-right"
                    className="transition-transform group-data-[state=open]/collapsible:rotate-90"
                  />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {AdminLinks.map((link) => (
                      <SidebarNavItem
                        to={link.path}
                        className="block"
                        key={link.path}
                      >
                        {({ isActive }) => (
                          <SidebarMenuItem className="pl-4">
                            <SidebarMenuButton
                              isActive={isActive}
                              className="pl-3"
                            >
                              {link.name}
                            </SidebarMenuButton>
                          </SidebarMenuItem>
                        )}
                      </SidebarNavItem>
                    ))}
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        </>
      ) : null}
    </>
  )
}

const SidebarNavItem = ({ ...props }: ComponentProps<typeof NavLink>) => {
  return <NavLink className="block" prefetch="intent" {...props} />
}
